import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { ArrowCircleRightIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Home = ({ data }) => (
<Layout>
  <div className="container main">
    <div id="news">
    <h2>News<span className="text-pink-500">.</span> <span className="h3">| 出演情報</span></h2>

    <div className="grid lg:grid-cols-2 rounded-lg bg-gray-50 flex-1 p-8">
      <StaticImage src="../images/sunflower.jpg" alt="ひまわりの花。" className="rounded-lg lg:mr-8" />
    <div className="lg:pl-8 mt-8 lg:my-auto">
      {data.upcoming.nodes.length === 0 && (
        // <p className="font-bold text-lg">向こう1週間のテレビ出演情報はありません。</p>
        <p className="font-bold text-lg">Check back soon for updates!</p>
      )}
      {data.upcoming.nodes && data.upcoming.nodes.map(node => (
        <div key={node.recordId} className="mb-5">
          <div><h3>{node.data.title}</h3></div>
          <p>
          <span className="tag">{node.data.station}</span>
          <span className="date text-pink-700">{node.data.date} | {node.data.time}</span>
          </p>
        </div>
      ))}
    </div>
    </div>
    
    <div className="grid md:grid-cols-2 gap-4 mt-12">
    {data.news.nodes.length === 0 && (
        <p className="font-bold text-lg">現在、新着情報はありません。</p>
      )}
      {data.news.nodes.map(node => (
        <a href={node.data.url} rel="noopener noreferrer" target="_blank" key={node.recordId}>
          <div className="card">
          <div className="title"><h3>{node.data.title}
          <span className="text-pink-500 ml-1"><ArrowCircleRightIcon className="h-6 w-6 inline lg:hidden" aria-label="詳細" /></span></h3></div>
          <p>{node.data.text}</p>
          </div>
        </a>
      ))}
    </div>
    </div>

    <Link to="/news"><button className="border-pink-500 hover:bg-pink-500 btn" type="button">過去の出演作品を見る</button></Link>

    <div id="web" className="mt-12">
    {/* <iframe allow="autoplay *; encrypted-media *; fullscreen *" title="Smiling" frameBorder="0" height="450" className="player m-auto mb-12" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/jp/album/smiling/720403556?l=ja"></iframe> */}
    <h2>Around the Web<span className="text-red-400">.</span> <span className="h3">| インタビュー記事等</span></h2>
    <div className="grid md:grid-cols-2 gap-4">
      {data.web.nodes.map(node => (
        <a href={node.data.url} rel="noopener noreferrer" target="_blank" key={node.recordId}>
        <div className="card">
        <div className="title"><h3>{node.data.title}<span className="text-red-400 ml-1"><ArrowCircleRightIcon className="h-6 w-6 inline lg:hidden" aria-label="詳細" /></span></h3></div>
        <p>
          <span className="tag">{node.data.tag}</span>
          <span className="date">{node.data.date} | {node.data.domain}</span>
        </p>
        </div>
        </a>
      ))}
    </div>
    </div>

    <Link to="/web"><button className="border-red-400 hover:bg-red-400 btn" type="button">もっと読む</button></Link>

    <div id="about" className="mt-12">
    <h2>About<span className="text-yellow-400">.</span> <span className="h3">| SAKAIzmについて</span></h2>
    <div className="grid md:grid-cols-2 gap-4">
    <p>当サイトは、1999年3月に運営を開始した女優・坂井真紀さんのファンサイトです。坂井真紀さんの所属する株式会社スターダストプロモーションとは一切関係ありません。</p>
    <div>
    <div className="title"><h3>お問い合せ</h3></div>
    <p>ご質問・ご意見・ご感想等は以下のフォームよりお願いいたします。</p>
    <a href="https://airtable.com/shrzAYP2Qmr4YxtqN" rel="noopener noreferrer" target="_blank"><button className="border-yellow-400 hover:bg-yellow-400 btn" type="button">問い合わせる</button></a>
    </div>
    </div>
    </div>
  </div>
</Layout>
)
export default Home;

export const query = graphql`
{
  upcoming:allAirtable(
    filter: {data: {checker: {eq: "yes"}, category: {eq: "tv"}}}
    sort: {fields: data___date, order: ASC}
    limit: 4
  ) {
    nodes {
      data {
        title
        station
        date(formatString: "YYYY.M.D")
        time
      }
      recordId
    }
  }

  news:allAirtable(
    filter: {data: {category: {eq: "news"}, Publish: {eq: true}, checker: {eq: "yes"}}}
    sort: {order: ASC, fields: data___date}
  ) {
    nodes {
      data {
        title
        url
        text
      }
      recordId
    }
  }

  web:allAirtable(
    filter: {data: {category: {eq: "web"}, Publish: {eq: true}}}
    sort: {order: DESC, fields: data___date}
    limit: 6
  ) {
    nodes {
      data {
        title
        url
        date(formatString: "YYYY.M.D")
        domain
        tag
      }
      recordId
    }
  }

}  
`;